import {
  applyMiddleware,
  combineReducers,
  compose,
  legacy_createStore as createStore,
} from "redux";
import thunk from "redux-thunk";
import { authReducer } from "../reducers/authReducer";
import { categoriesReducer } from "../reducers/categoriesReducer";
import { collectionsReducer } from "../reducers/collectionsReducer";
import { createCollectionReducer } from "../reducers/createCollectionReducer";
import { modalReducer } from "../reducers/modalReducer";
import { reportCategoriesReducer } from "../reducers/reportCategoriesReducer";
import { sortByCategoryReducer } from "../reducers/sortByCategoryReducer";
import { storesReducer } from "../reducers/storesReducer";
import { utilsReducer } from "../reducers/utilsReducer";
import { notificationsReducer } from "../reducers/notificationsReducer";
import { logUserReducer } from "../reducers/logUserReducer";

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const reducers = combineReducers({
  auth: authReducer,
  stores: storesReducer,
  modals: modalReducer,
  categories: categoriesReducer,
  reportCategories: reportCategoriesReducer,
  utils: utilsReducer,
  collections: collectionsReducer,
  orderByCategory: sortByCategoryReducer,
  createCollection: createCollectionReducer,
  notifications: notificationsReducer,
  logUser: logUserReducer,
});

export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk))
);
