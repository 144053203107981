import { catalogApi, collectionApi } from "./config";

const getAllCollections = async (params) => {
  try {
    const { data } = await collectionApi.get("/api/v1/Collection/all", {
      params,
    });
    return { data };
  } catch (error) {
    throw new Error("No se ha podido cargar el listado de colecciones.");
  }
};

const getCollectionById = async (params) => {
  try {
    const { data } = await collectionApi.get(
      `/api/v1/Collection/${params.id}`,
      {
        params,
      }
    );
    return data.Data;
  } catch (error) {
    throw new Error("No se encuentran productos para la coleccion");
  }
};

const getProductsByMultipleCategories = async ({
  username,
  store,
  categories,
  order,
}) => {
  try {
    const url = `/api/v1/Catalogue/dinamycSorttable/page/1/1000?${categories}`;

    const {
      data: { data },
    } = await catalogApi.post(url, order, { params: { username, store } });

    return data?.content;
  } catch (error) {
    throw new Error(
      "No se ha podido cargar el listado de productos de las categorias."
    );
  }
};

///////////////////// SMARTMAN COLLECTION //////////////////////////

/**
 * CreateSmartmanCollection.
 * @type {Object} data - Objeto con la siguiente estructura:
 * @body {Object} data.id
 * @body {string} data.id.name
 * @body {string} data.id.store
 * @body {string} data.description
 * @body {boolean} data.active
 * @body {boolean} data.highlight
 * @body {string} data.searchable
 * @body {string} data.startDate
 * @body {string} data.endDate
 * @body {string} data.vtexId
 * @body {boolean} data.inVtex
 */
const createSmartmanCollection = async (user, data) => {
  try {
    const url = "/api/v1/SmartmanCollection/create?username=" + user;
    const { data: response } = await collectionApi.post(url, {
      ...data,
      // nameCollection: data.id.name.trim().replaceAll("%", "%25"),
      // id: { ...data.id, name: data.id.name.trim().replaceAll("%", "%25") },
    });
    return response;
  } catch (error) {
    throw new Error("No se ha podido crear la colección");
  }
};

/**
 * getSmartmanCollectionByStoreAndName.
 * @param {String} store
 * @param {String} name
 */
const getSmartmanCollectionByStoreAndName = async (store, name) => {
  try {
    // const newName = name.replaceAll("%", "%25");
    const newName = encodeURIComponent(name);
    const url = `/api/v1/SmartmanCollection/${store}/${newName}`;
    const { data: response } = await collectionApi.get(url);
    return response;
  } catch (error) {
    throw new Error("No se ha podido obtener la colección");
  }
};

/**
 * getSmartmanCollectionByStore.
 * @param {String} store
 */
const getSmartmanCollectionByStore = async (store) => {
  try {
    const url = `/api/v1/SmartmanCollection/get/${store}`;
    const { data: response } = await collectionApi.get(url);
    return response;
  } catch (error) {
    throw new Error("No se ha podido obtener la colección");
  }
};

/**
 * updateSmartmanCollection.
 * @type {Object} data - Objeto con la siguiente estructura:
 * @body {Object} data.id
 * @body {string} data.id.name
 * @body {string} data.id.store
 * @body {string} data.description
 * @body {boolean} data.active
 * @body {boolean} data.highlight
 * @body {string} data.searchable
 * @body {string} data.startDate
 * @body {string} data.endDate
 * @body {string} data.vtexId
 * @body {boolean} data.inVtex
 */
const updateSmartmanCollection = async (user, data) => {
  try {
    const url = "/api/v1/SmartmanCollection/update?username=" + user;
    const { data: response } = await collectionApi.put(url, {
      ...data,
      // id: { ...data.id, name: data.id.name.trim().replaceAll("%", "%25") },
    });
    return response;
  } catch (error) {
    throw new Error("No se ha podido actualizar la colección");
  }
};

/**
 * deleteSmartmanCollection.
 * @param {String} store
 * @param {String} name
 */
const deleteSmartmanCollection = async (store, name) => {
  try {
    // const newName = name.replaceAll("%", "%25");
    const newName = encodeURIComponent(name);
    const url = `/api/v1/SmartmanCollection/delete/${store}/${newName}`;
    const { data: response } = await collectionApi.delete(url);
    return response;
  } catch (error) {
    throw new Error("No se ha podido eliminar la colección");
  }
};

/**
 * getSmartmanCollectionGroup.
 * @param {String} store
 * @param {String} name
 */
const getSMGroupByName = async (store, name) => {
  try {
    // let newName = name.replaceAll("%", "%25");
    const newName = encodeURIComponent(name);
    const url = `/api/v1/SmartmanCollection/group/get/${store}/${newName}`;
    const { data: response } = await collectionApi.get(url);
    return response;
  } catch (error) {
    throw new Error("No se ha podido obtener el grupo de la colección");
  }
};

/**
 * createSmartmanCollectionGroup.
 * @type {Object} data - Objeto con la siguiente estructura:
 * @body {Object} data.id
 * @body {string} data.id.name
 * @body {string} data.id.store
 * @body {string} data.description
 * @body {boolean} data.active
 */
const createSmartmanCollectionGroup = async (data) => {
  try {
    const url = "/api/v1/SmartmanCollection/group/create";
    const { data: response } = await collectionApi.post(url, {
      ...data,
      // id: { ...data.id, name: data.id.name.trim().replaceAll("%", "%25") },
    });
    return response;
  } catch (error) {
    throw new Error("No se ha podido crear el grupo de la colección");
  }
};

/**
 * getSmartmanCollectionGroupsByStore.
 * @param {String} store
 */
const getSmartmanCollectionGroupsByStore = async (store) => {
  try {
    const url = `/api/v1/SmartmanCollection/group/get/${store}`;
    const { data: response } = await collectionApi.get(url);
    return response;
  } catch (error) {
    throw new Error("No se ha podido obtener el grupo de la colección");
  }
};

/**
 * updateSmartmanCollectionGroup.
 * @type {Object} data - Objeto con la siguiente estructura:
 * @body {Object} data.id
 * @body {string} data.id.name
 * @body {string} data.id.store
 * @body {string} data.description
 * @body {boolean} data.active
 */
const updateSmartmanCollectionGroup = async (data) => {
  try {
    const url = "/api/v1/SmartmanCollection/group/update";
    const { data: response } = await collectionApi.put(url, data);
    return response;
  } catch (error) {
    throw new Error("No se ha podido actualizar el grupo de la colección");
  }
};

/**
 * deleteSmartmanCollectionGroup.
 * @param {String} store
 * @param {String} name
 */
const deleteSmartmanCollectionGroup = async (store, name) => {
  try {
    // const newName = name.replaceAll("%", "%25");
    const newName = encodeURIComponent(name);
    const url = `/api/v1//SmartmanCollection/group/delete/${store}/${newName}`;
    const { data: response } = await collectionApi.delete(url);
    return response;
  } catch (error) {
    throw new Error("No se ha podido eliminar el grupo de la colección");
  }
};

/**
 * addSmartmanCollectionToGroup.
 * @param {String} store
 * @param {String} groupName
 * @body {String} collectionName
 */
const addSmartmanCollectionToGroup = async (data) => {
  try {
    const url = `/api/v1/SmartmanCollection/group/add/${data.exclude}`;
    const { data: response } = await collectionApi.post(url, data);
    return response;
  } catch (error) {
    throw new Error("No se ha podido agregar el grupo  a la colección");
  }
};

/**
 * removeSmartmanCollectionGroupToCollection.
 * @param {String} store
 * @param {String} name
 * @typedef {Object} data - Objeto con la siguiente estructura:
 * @body {String} data.collectionName
 */
const removeSmartmanCollectionGroupToCollection = async (deleteGroup) => {
  try {
    const url = `/api/v1/SmartmanCollection/group/remove`;
    const { data: response } = await collectionApi.delete(url, {
      data: deleteGroup,
    });

    return response;
  } catch (error) {
    throw new Error("No se ha podido eliminar la colección del grupo");
  }
};

/**
 * getSmartmanCollectionGroupsByCollection.
 * @param {String} store
 * @param {String} collectionName
 */
const getSmartmanCollectionGroupsByCollection = async (
  store,
  collectionName
) => {
  try {
    const newgcollectionName = encodeURIComponent(collectionName);
    const url = `/api/v1/SmartmanCollection/${store}/${newgcollectionName}/groups`;
    const { data: response } = await collectionApi.get(url);
    return response;
  } catch (error) {
    throw new Error("No se ha podido obtener los grupos de la colección");
  }
};

/**
 * getSMGroupFilters.
 * @param {String} store
 * @param {String} groupName
 */
const getSMGroupFilters = async (store, groupName) => {
  try {
    // const newgroupName = groupName.replaceAll("%", "%25");
    const newgroupName = encodeURIComponent(groupName);
    const url = `/api/v1/SmartmanCollection/group/${store}/${newgroupName}/filters`;
    const { data: response } = await collectionApi.get(url);
    return response;
  } catch (error) {
    throw new Error("No se ha podido obtener los filtros del grupo");
  }
};

/**
 * createSmartmanCollectionGroupFilter.
 * @param {String} store
 * @param {String} groupName
 * @typedef {Object} data estructura del objeto:
 * @Body {Object} data.id
 * @Body {String} data.id.id
 * @Body {String} data.id.store
 * @Body {String} data.id.groupName
 * @Body {String} data.input
 * @Body {String} data.operator
 * @Body {String} data.value
 * @Body {boolean} data.exclude
 */
const createSmartmanCollectionGroupFilter = async (store, groupName, data) => {
  try {
    // const newgroupName = groupName.replaceAll("%", "%25");
    const newgroupName = encodeURIComponent(groupName);

    const url = `/api/v1/SmartmanCollection/group/${store}/${newgroupName}/filter/create`;
    const { data: response } = await collectionApi.post(url, data);
    return response;
  } catch (error) {
    throw new Error("No se ha podido crear el filtro del grupo");
  }
};

/**
 * updateSmartmanCollectionGroupFilter.
 * @param {String} store
 * @param {String} groupName
 * @typedef {Object} data estructura del objeto:
 * @Body {Object} data.id
 * @Body {String} data.id.id
 * @Body {String} data.id.store
 * @Body {String} data.id.groupName
 * @Body {String} data.input
 * @Body {String} data.operator
 * @Body {String} data.value
 * @Body {boolean} data.exclude
 */
const updateSmartmanCollectionGroupFilter = async (store, groupName, data) => {
  try {
    // const newgroupName = groupName.replaceAll("%", "%25");
    const newgroupName = encodeURIComponent(groupName);
    const url = `/api/v1/SmartmanCollection/group/${store}/${newgroupName}/filter/update`;
    const { data: response } = await collectionApi.put(url, data);
    return response;
  } catch (error) {
    throw new Error("No se ha podido actualizar el filtro del grupo");
  }
};

/**
 * deleteSmartmanCollectionGroupFilter.
 * @param {String} store
 * @param {String} groupName
 * @typedef {Object} data estructura del objeto:
 * @Body {Object} data.smartmanCollectionGroupFilterId
 */
const deleteSmartmanCollectionGroupFilter = async (
  store,
  groupName,
  idFilter
) => {
  try {
    // const newgroupName = groupName.replaceAll("%", "%25");
    const newgroupName = encodeURIComponent(groupName);
    const url = `/api/v1/SmartmanCollection/group/${store}/${newgroupName}/filter/delete/${idFilter}`;
    const { data: response } = await collectionApi.delete(url);
    return response;
  } catch (error) {
    throw new Error("No se ha podido eliminar el filtro del grupo");
  }
};

/**
 * getSmartmanCollectionGroupTypes.
 */
const getSmartmanCollectionGroupTypes = async () => {
  try {
    const url = `/api/v1/SmartmanCollection/group/types`;
    const { data: response } = await collectionApi.get(url);
    return response;
  } catch (error) {
    throw new Error("No se ha podido obtener los tipos de grupo");
  }
};
const addProductsToSMGroup = async ({ products, store, name }) => {
  try {
    // const newgroupName = name.replaceAll("%", "%25");
    const newgroupName = encodeURIComponent(name);
    const url = `/api/v1/SmartmanCollection/addProducts/${store}/${newgroupName}`;
    const { data: response } = await collectionApi.post(url, products);
    return response;
  } catch (error) {
    throw new Error("No se ha podido agregar items al grupo");
  }
};

/**
 * getSmartmanCollectionGroupItems.
 * @param {String} store
 * @param {String} groupName
 */
const getSmartmanCollectionGroupItems = async (store, groupName) => {
  try {
    // const newgroupName = groupName.replaceAll("%", "%25");
    const newgroupName = encodeURIComponent(groupName);
    const url = `/api/v1/SmartmanCollection/group/${store}/${newgroupName}/items`;
    const { data: response } = await collectionApi.get(url);
    return response;
  } catch (error) {
    throw new Error("No se ha podido obtener los items del grupo");
  }
};

/**
 * createSmartmanCollectionGroupItem.
 * @param {String} store
 * @param {String} groupName
 * @typedef {Object} data estructura del objeto:
 * @Body {Object} data.id
 * @Body {String} data.id.id
 * @Body {String} data.id.store
 * @Body {String} data.id.groupName
 * @Body {String} data.typeId
 * @Body {boolean} data.exclude
 */
const createSmartmanCollectionGroupItem = async (store, groupName, data) => {
  try {
    // const newGroupName = groupName.replaceAll("%", "%25");
    const newGroupName = encodeURIComponent(groupName);
    const url = `/api/v1/SmartmanCollection/group/${store}/${newGroupName}/item/create`;
    const { data: response } = await collectionApi.post(url, data);
    return response;
  } catch (error) {
    throw new Error("No se ha podido crear el item del grupo");
  }
};

const getProductsSMGroup = async (store, groupName) => {
  try {
    const newGroupName = encodeURIComponent(groupName);
    const url = `/api/v1/group/ProductsByGroup?group=${newGroupName}&store=${store}`;
    const { data: response } = await collectionApi.get(url);

    return response;
  } catch (error) {
    throw new Error("No se ha podido obtener productos del grupo");
  }
};

const saveSmartmanCollectionFilled = async (
  store,
  collectionId,
  username,
  data
) => {
  try {
    // const newcollectionId = collectionId.replaceAll("%", "%25");
    const newcollectionId = encodeURIComponent(collectionId);
    const url = `/api/v1/SmartmanCollection/fill/${store}/${newcollectionId}/${username}`;
    const { data: response } = await collectionApi.post(url, data);
    return response;
  } catch (error) {
    throw new Error("No se ha podido obtener productos del grupo");
  }
};

const reCreateCollectionFromTemporalProductsSort = async ({
  store,
  username,
  collectionId,
  data = [],
  logUserCriterias,
}) => {
  try {
    const url = `/api/v1/SmartmanCollection/fillFromTemporalProducts/${store}/${username}?collectionId=${collectionId}`;
    const { data: response } = await collectionApi.post(url, {
      products: data,
      logUserCriterias,
    }); //TODO CHANGE ENDPOINT BACKEND TO RECIVE LOG USER AND DATA ITEMS,validate error [] log back
    return response;
  } catch (error) {
    throw new Error("No se ha podido indexar de manera completa  la colección");
  }
};

const getAllProductsByCollection = async (store, nameCollection) => {
  try {
    // const newNameCollectionEscaped = nameCollection.replaceAll("%", "%25");
    const newNameCollectionEscaped = encodeURIComponent(nameCollection);
    const url = `api/v1/group/getAllProductsByCollection?store=${store}&name=${newNameCollectionEscaped}`;
    const { data: response } = await collectionApi.get(url);
    return response;
  } catch (error) {
    throw new Error("No se ha podido obtener los productos de la colección");
  }
};

/**
 * deleteSmartmanCollectionGroupItem.
 * @param {String} store
 * @param {String} groupName
 * @param {String} smartmanCollectionGroupItemId
 */
const deleteSmartmanCollectionGroupItem = async (
  store,
  groupName,
  smartmanCollectionGroupItemId
) => {
  try {
    // const newgroupName = groupName.replaceAll("%", "%25");
    const newgroupName = encodeURIComponent(groupName);
    const url = `/api/v1/SmartmanCollection/group/${store}/${newgroupName}/item/delete?smartmanCollectionGroupItemId=${smartmanCollectionGroupItemId}`;
    const { data: response } = await collectionApi.delete(url);
    return response;
  } catch (error) {
    throw new Error("No se ha podido eliminar el item del grupo");
  }
};

/**
 * createSmartmanCollectionGroupItemFromFile.
 * @param {String} store
 * @param {String} groupName
 * @typedef {Object} data estructura del objeto:
 * @Body {String} data.fileBase64
 */
const createSmartmanCollectionGroupItemFromFile = async (
  store,
  username,
  groupName,
  data
) => {
  try {
    // const newgroupName = groupName.replaceAll("%", "%25");
    const newgroupName = encodeURIComponent(groupName);
    const url = `/api/v1/SmartmanCollection/group/${store}/${newgroupName}/add/products/file?username=${username}`;
    const { data: response } = await collectionApi.post(url, { key: data });
    return response;
  } catch (error) {
    throw new Error("No se ha podido crear el item del grupo");
  }
};

/**
 * sendSmartmanCollectionToVtex.
 * @param {String} store
 * @param {String} name
 */
const sendSmartmanCollectionToVtex = async (store, name) => {
  try {
    // const newName = name.replaceAll("%", "%25");
    const newName = encodeURIComponent(name);
    const url = `/api/v1/SmartmanCollection/${store}/${newName}/vtex/send`;
    const { data: response } = await collectionApi.post(url);
    return response;
  } catch (error) {
    throw new Error("No se ha podido enviar la colección a VTEX");
  }
};

export {
  addProductsToSMGroup,
  addSmartmanCollectionToGroup,
  // ------------------ SmartmanCollection ------------------
  createSmartmanCollection,
  createSmartmanCollectionGroup,
  createSmartmanCollectionGroupFilter,
  createSmartmanCollectionGroupItem,
  createSmartmanCollectionGroupItemFromFile,
  deleteSmartmanCollection,
  deleteSmartmanCollectionGroup,
  deleteSmartmanCollectionGroupFilter,
  deleteSmartmanCollectionGroupItem,
  getAllCollections,
  getAllProductsByCollection,
  getCollectionById,
  getProductsByMultipleCategories,
  getSmartmanCollectionByStore,
  getSmartmanCollectionByStoreAndName,
  getSMGroupByName,
  getSMGroupFilters,
  getSmartmanCollectionGroupItems,
  getSmartmanCollectionGroupsByCollection,
  getSmartmanCollectionGroupsByStore,
  getSmartmanCollectionGroupTypes,
  getProductsSMGroup,
  reCreateCollectionFromTemporalProductsSort,
  removeSmartmanCollectionGroupToCollection,
  saveSmartmanCollectionFilled,
  sendSmartmanCollectionToVtex,
  updateSmartmanCollection,
  updateSmartmanCollectionGroup,
  updateSmartmanCollectionGroupFilter,
};
