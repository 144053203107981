import { Collapse, MenuItem, Select } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { startSavePlanActual } from "../../../actions/auth";
import { get, set } from "../../../helpers/sessionStorage";
import iconEn from "../../../images/header/en.svg";
import iconEs from "../../../images/header/es.svg";
import iconPlan from "../../../images/header/icon_plan.svg";
import iconPt from "../../../images/header/pt.svg";
import { Button } from "../../atoms/Button";
import { Div } from "../../atoms/Div";
import { Image } from "../../atoms/Image";
import { BurgerMenu } from "../../molecules/burgerMenu/BurgerMenu";
import StoreSelect from "../../molecules/mainheader/StoreSelect";
import { Popup } from "../../molecules/orderbycategory/Popup";
import { DropDownMenu } from "../drop-down-menu/DropDownMenu";
import { QuickDemo } from "../quickdemo/QuickDemo";

const LANGS = ["es", "en", "pt"];

export const MainHeader = memo(function MainHeader({ keycloak }) {
  const dispatch = useDispatch();
  const stories = useSelector((state) => state.stores, shallowEqual);
  const { notifications } = useSelector(
    (state) => state.notifications,
    shallowEqual
  );

  const auth = useSelector((state) => state.auth, shallowEqual);
  const { t, i18n } = useTranslation();
  const [location, setlocation] = useState(() => {
    let language = LANGS.filter((lang) => i18n?.location?.search(lang) > -1);
    return language.length > 0 ? language[0] : "es";
  });

  const [visibleQuickDemo, setVisibleQuickDemo] = useState(false);
  const [visibleNotification, setVisibleNotification] = useState(false);

  const toggleUserActions = (value) => {
    if (value === "notifications") {
      setVisibleNotification(!visibleNotification);
    } else {
      setVisibleQuickDemo(!visibleQuickDemo);
    }
  };

  const handleChange = ({ target }) => {
    setlocation(target.value);
    set("language", target.value);
  };

  useEffect(() => {
    if (stories.actualstore.store !== "Selecciona una tienda") {
      dispatch(startSavePlanActual(auth.email, stories.actualstore.store));
    }
  }, [stories.actualstore.store, auth.email, dispatch]);

  useEffect(() => {
    i18n.changeLanguage(location);
  }, [location, i18n]);

  useEffect(() => {
    const language_selected = get("language");
    language_selected && setlocation(language_selected);
  }, []);

  return (
    <>
      <Div className="mainheader">
        <Div className="mainheader__left-side">
          <Div className="burgerMenu">
            <BurgerMenu className="burgerMenu" />
          </Div>
          <Link className="mainheader__smartman-logo" to="/home" />
          <StoreSelect />
          <Div className="mainheader__plan">
            <Image source={iconPlan} className="mainheader__plan__icon" />
            Plan {auth.planactual.toLowerCase()}
          </Div>
        </Div>
        <Div className="mainheader__right-side">
          <Link className="mainheader__transparent-button" to="/user-profile">
            {t("header.button")}
          </Link>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            IconComponent={ExpandMore}
            value={location}
            onChange={handleChange}
            autoWidth
            label="Age"
            variant="standard"
          >
            <MenuItem value="es">
              <Image className="flag__location" source={iconEs} />
            </MenuItem>
            <MenuItem value="en">
              <Image className="flag__location" source={iconEn} />
            </MenuItem>
            <MenuItem value="pt">
              <Image className="flag__location" source={iconPt} />
            </MenuItem>
          </Select>
          <Button
            className="mainheader__quickdemo-button"
            onClick={() => toggleUserActions("quickdemo")}
          />
          <QuickDemo
            visible={visibleQuickDemo}
            toggleUserActions={() => toggleUserActions("quickdemo")}
          ></QuickDemo>

          <Div
            style={{
              width: "30px",
              height: "30px",
              color: "#1996fc",
              position: "relative",
            }}
            onClick={() => toggleUserActions("notifications")}
          >
            <svg viewBox="0 0 512 512" className="w-full h-full text-[#1996fc]">
              <path d="M192 416h128c0 35.3-28.7 64-64 64s-64-28.7-64-64m160-256c0-42-26.9-77.6-64.4-90.7.3-1.7.4-3.5.4-5.3 0-17.7-14.3-32-32-32s-32 14.3-32 32c0 1.8.2 3.6.4 5.3C186.9 82.4 160 118 160 160c0 88.4-43 160-96 160v64h384v-64c-53 0-96-71.6-96-160"></path>
            </svg>
            {notifications.length > 0 && (
              <span
                style={{
                  backgroundColor: "red",
                  borderRadius: "50%",
                  padding: "0px 8px",
                  position: "absolute",
                  right: "-4px",
                  top: "15px",
                  color: "white",
                }}
              >
                {notifications.length}
              </span>
            )}
          </Div>
          <Collapse in={visibleNotification} className="quick-demo">
            <Div className="quick-demo__header">
              <Div className="quick-demo__title">Notificaciones</Div>
              <Div
                className="quick-demo__close"
                onClick={() => toggleUserActions("notifications")}
              ></Div>
            </Div>
            {notifications.map((notf, idx) => {
              return (
                <>
                  <div
                    title={idx + 1}
                    style={{
                      padding: "10px 15px",
                      color: "#1996fc",
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                    }}
                  >
                    {notf}
                  </div>
                </>
              );
            })}
            <Div className="quick-demo__themes-sumary"></Div>
          </Collapse>
          <DropDownMenu keycloak={keycloak} />
        </Div>
      </Div>
      <Popup />
    </>
  );
});
