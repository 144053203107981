import { types } from "../types/types";

const initialState = {
  sortCriteriasSelected: [],
  id: "",
};
export const logUserReducer = (state = initialState, action) => {
  console.log({ state, action });
  switch (action.type) {
    case types.saveLogUser:
      return {
        ...state,
        sortCriteriasSelected: action.payload,
      };

    default:
      return state;
  }
};
// const { sortCriteriaSelected } = useSelector((state) => state.logUser, shallowEqual);

// const dispatch = useDispatch();
// dispatch(saveLogUser({ log: [...sortCriteriasSelected,{custom:"down"}] }));
